import { Component, Watch } from 'vue-property-decorator'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { mapState } from 'vuex'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import moment from 'moment'

@Component({
  name: 'GtrEventPaymentsView',
  computed: {
    ...mapState('event', ['event', 'event_stats', 'eventAllContent'])
  }
})
export default class GtrEventPaymentsView extends GtrSuper {
  data () {
    return {
      payment_processor_currency: null,
      event_all_content: null,
      loading: false,
      tab: 0,
      tabs: [
        { name: 'Payments' },
        { name: 'Open Invoices' },
        { name: 'Abandoned Carts' }
      ],
      paymentCounterData: {},
      paymentsTableFilters: [
        { name: 'Credit Card', value: 'credit_card' },
        { name: 'Payorder', value: 'payorder' },
        { name: 'Check', value: 'check' },
        { name: 'Cash', value: 'cash' }
      ],
      selectedPaymentsTableFilters: [],
      paymentsTableHeaders: [
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Company', value: 'company' },
        { text: 'Date/Time', value: 'date_time' },
        { text: 'Transaction ID', value: 'transaction_id' },
        { text: 'Method', value: 'method' },
        { text: '', value: 'participant_uuid' }
      ],
      paymentsTableItems: [],
      filteredPaymentsTableItems: [],
      paymentsByMethodPieChartOptions: {
        responsive: true,
        pieHole: 0.6,
        pieSliceText: 'none',
        hoverBorderWidth: 20,
        title: '',
        width: '100%',
        height: 300,
        colors: ['#F19C4F', '#6AD5D4', '#5F6FE8', '#EC7F93']
      },
      paymentCounterOptions: {
        credit_card: {
          color: '#F19C4F',
          label: 'Credit Card'
        },
        payorder: {
          color: '#6AD5D4',
          label: 'Payorder'
        },
        check: {
          color: '#5F6FE8',
          label: 'Check'
        },
        cash: {
          color: '#EC7F93',
          label: 'Cash'
        }
      },
      paymentsByMethodPieChartData: [],
      paymentsOverTimeStartDateFilter: '',
      paymentsOverTimeStartDateFilterItems: [
        {
          text: 'All Time',
          value: 10000
        },
        {
          text: 'Yesterday',
          value: 1
        },
        {
          text: 'Last 7 Days',
          value: 7
        },
        {
          text: 'Last 14 Days',
          value: 14
        },
        {
          text: 'Last 30 Days',
          value: 30
        },
        {
          text: 'Last 3 Months',
          value: 90
        },
        {
          text: 'Last 6 Months',
          value: 180
        },
        {
          text: 'Last 12 Months',
          value: 365
        }
      ],
      paymentsOverTimeEndDateFilter: '',
      paymentsOverTimeLineChartOptions: {
        title: '',
        curveType: 'function',
        pointSize: 5,
        width: '100%',
        height: 300,
        legend: {
          position: 'bottom'
        },
        colors: ['#F19C4F', '#6AD5D4', '#5F6FE8', '#EC7F93']
      },
      paymentsOverTimeLineChartData: [
        ['Date', 'Credit Card', 'Payorder', 'Check', 'Cash', 'Other']
      ],
      filteredPaymentsOverTimeLineChartData: [
        ['Date', 'Credit Card', 'Payorder', 'Check', 'Cash', 'Other']
      ],
      openInvoicesTableHeaders: [
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Company', value: 'company' },
        { text: 'Date/Time', value: 'date_time' },
        { text: 'Transaction ID', value: 'transaction_id' },
        { text: '', value: 'url' },
        { text: '', value: 'participant_uuid' }
      ],
      openInvoicesTableItems: [],
      abandonedCartsTableHeaders: [
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Company', value: 'company' },
        { text: 'Reg Fee', value: 'reg_fee' },
        { text: '', value: 'participant_uuid' }
      ],
      abandonedCartsTableItems: [],
      totalNumberOfTransactions: 0,
      searchPayments: '',
      searchOpenInvoices: '',
      searchAbandonedCarts: ''
    }
  }

  @Watch('eventAllContent', { immediate: true })
  onEventAllContentChange (value: any) {
    if (value) {
      this.$data.event_all_content = value
      this.$data.payment_processor_currency = this.$data.event_all_content.payment_processor_currency
    }
  }

  @Watch('event_stats')
  onEventStatsChange (payload) {
    const { invoices, transactionTotals, transactionTotalsByDate, transactions, abandonedCarts } = payload
    this.$data.totalNumberOfTransactions = transactions.length
    /**
     * Payments data formatting
     */
    const transactionsFormatted: any = []
    for (const transaction of transactions) {
      const transactionFormatted = {
        name: `${transaction.first_name} ${transaction.last_name}`,
        email: transaction.email,
        company: transaction.company === 'null' ? null : transaction.company,
        date_time: transaction.created_at,
        transaction_id: transaction.transaction_uuid,
        method: transaction.payment_type,
        participant_uuid: transaction.participant_uuid
      }
      transactionsFormatted.push(transactionFormatted)
    }
    this.$data.paymentsTableItems = transactionsFormatted
    this.$data.filteredPaymentsTableItems = transactionsFormatted
    /**
     * Open Invoices data formatting
     */
    const invoicesFormatted: any = []
    for (const invoice of invoices) {
      const invoiceFormatted = {
        name: `${invoice.first_name} ${invoice.last_name}`,
        email: invoice.email,
        company: invoice.company === 'null' ? null : invoice.company,
        date_time: invoice.created_at,
        transaction_id: invoice.invoice_uuid,
        url: invoice.url,
        participant_uuid: invoice.participant_uuid
      }
      invoicesFormatted.push(invoiceFormatted)
    }
    this.$data.openInvoicesTableItems = invoicesFormatted
    /**
     * Abandoned Carts data formatting
     */
    const abandonedCartsFormatted: any = []
    for (const abandonedCart of abandonedCarts) {
      const abandonedCartFormatted = {
        name: `${abandonedCart.first_name} ${abandonedCart.last_name}`,
        email: abandonedCart.email,
        company: abandonedCart.company === 'null' ? null : abandonedCart.company,
        reg_fee: abandonedCart.cur_reg_fee,
        participant_uuid: abandonedCart.uuid
      }
      abandonedCartsFormatted.push(abandonedCartFormatted)
    }
    this.$data.abandonedCartsTableItems = abandonedCartsFormatted
    /**
     * Payments Over Time line chart
     */
    this.$data.paymentsOverTimeLineChartData = this.formatLineChartData(transactionTotalsByDate)
    this.$data.filteredPaymentsOverTimeLineChartData = this.$data.paymentsOverTimeLineChartData
    /**
     * Payments By Method pie chart
     */
    this.$data.paymentsByMethodPieChartData = this.formatPieChartData(transactionTotalsByDate)
    /**
     * Payment Counter data
     */
    const paymentCounterData: any = this.formatPaymentCounterData(transactionTotals)
    this.$data.paymentCounterData = Object.assign({}, this.$data.paymentCounterData, paymentCounterData)
  }

  formatPaymentCounterData (data) {
    const paymentCounterData = {}
    const keys = Object.keys(data?.Charge || {})
    for (const key of keys) {
      const keyFormatted = key.indexOf('CreditCard') !== -1 ? 'credit_card' : key.toLowerCase()
      if (!['credit_card', 'cash', 'payorder', 'check'].includes(keyFormatted)) {
        continue
      }
      paymentCounterData[keyFormatted] = parseFloat(data.Charge[key])
    }
    return paymentCounterData
  }

  formatPieChartData (data) {
    const result = [
      ['Label', 'Total']
    ]
    const labelMap = {
      credit_card: 'Credit Card',
      payorder: 'Payorder',
      check: 'Check',
      cash: 'Cash'
    }
    const aggregateHash = {
      credit_card: 0,
      payorder: 0,
      check: 0,
      cash: 0
    }
    for (const date in data) {
      aggregateHash.credit_card += data[date]?.credit_card || 0
      aggregateHash.payorder += data[date]?.payorder || 0
      aggregateHash.check += data[date]?.check || 0
      aggregateHash.cash += data[date]?.cash || 0
    }
    for (const method in aggregateHash) {
      const row = [labelMap[method], aggregateHash[method]]
      result.push(row)
    }
    if (result.length === 1) {
      return []
    }
    return result
  }

  formatLineChartData (data) {
    const result: any = [
      ['Date', 'Credit Card', 'Payorder', 'Check', 'Cash']
    ]
    for (const date in data) {
      const row = [
        date,
        data[date]?.credit_card || 0,
        data[date]?.payorder || 0,
        data[date]?.check || 0,
        data[date]?.cash || 0
      ]
      result.push(row)
    }
    if (result.length === 1) {
      return []
    }
    return result
  }

  async mounted () {
    try {
      this.$data.loading = true
      await Promise.all([
        this.$store.dispatch('event/fetchEvent', this.$route.params.event_uuid),
        this.$store.dispatch('event/getEventStats', this.$route.params.event_uuid)
      ])
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  filterTableData (filters, propertyToFilter, data) {
    if (!filters.length) {
      return data
    }
    const filteredData: any = []
    for (const row of data) {
      if (filters.includes(row[propertyToFilter])) {
        filteredData.push(row)
      }
    }
    return filteredData
  }

  getLineChartStartDate (daysBack: number) {
    return moment().subtract(daysBack, 'days').format('YYYY-MM-DD')
  }

  getLineChartEndDate () {
    return moment().format('YYYY-MM-DD')
  }

  filterChartData (startDate, endDate, headersAndData) {
    const headers = headersAndData[0]
    const data = headersAndData.slice(1)
    const beginning = startDate ? new Date(startDate).getTime() : 0
    const end = endDate ? new Date(endDate).getTime() : Infinity
    const dataWithinRange: any = []
    for (const row of data) {
      const date = new Date(row[0]).getTime()
      if (date >= beginning && date <= end) {
        dataWithinRange.push(row)
      }
    }
    if (!dataWithinRange.length) {
      return [
        headers,
        new Array(headers.length).fill(0)
      ]
    }
    return [
      headers,
      ...dataWithinRange
    ]
  }

  get totalAbandonedValue () {
    return this.$data.abandonedCartsTableItems.reduce((acc, item) => {
      const fee = parseFloat(item.reg_fee)
      return acc + fee
    }, 0.0)
  }
}
